// Generated by Framer (5d34d90)

import * as React from "react";
import { motion, LayoutGroup } from "framer-motion";
import { useOnVariantChange, useActiveVariantCallback, Image, addFonts, withCSS, addPropertyControls, ControlType, cx, useVariantState, CycleVariantState, Text } from "framer";

const cycleOrder = ["Mzjgj_AwE"];

const variantClassNames = {Mzjgj_AwE: "framer-v-1t6dex3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {type: "spring", ease: [0.44, 0, 0.56, 1], duration: 0.3, delay: 0, stiffness: 500, damping: 60, mass: 1}};

const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

const useRandomID = () => {const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "Mzjgj_AwE", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const { variants, baseVariant, gestureVariant, classNames, transition, setVariant, setGestureState } = useVariantState({defaultVariant: "Mzjgj_AwE", variant, transitions, variantClassNames, cycleOrder});
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID();

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-QmOTM", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? undefined}}>
<motion.div {...restProps} className={cx("framer-1t6dex3", className)} style={{backgroundColor: "rgb(0, 0, 0)", ...style}} layoutId={"Mzjgj_AwE"} transition={transition} layoutDependency={layoutDependency} data-framer-name={"Variant 1"} ref={ref}><motion.div className={"framer-1k0bxk4"} style={{backgroundColor: "rgb(0, 0, 0)"}} layoutId={"Agw5nXnQQ"} transition={transition} layoutDependency={layoutDependency}><Text style={{"--framer-font-family": "\"Clash Display\", serif", "--framer-font-style": "normal", "--framer-font-weight": 600, "--framer-text-color": "rgb(255, 255, 255)", "--framer-font-size": "35px", "--framer-letter-spacing": "0px", "--framer-text-transform": "none", "--framer-text-decoration": "none", "--framer-line-height": "1.2em", "--framer-text-alignment": "left"}} withExternalLayout={true} verticalAlignment={"top"} __fromCanvasComponent={true} alignment={"left"} fonts={["GF;Clash Display-600"]} className={"framer-nkxw7"} layoutId={"sC9BN2f1u"} transition={transition} layoutDependency={layoutDependency} rawHTML={"<span style='font-size: 0; line-height: 0; tab-size: 4; white-space: inherit; word-wrap: inherit'><span style='font-size: 0'><span style=''>sassy</span><br></span></span>"}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-QmOTM [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none;}", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QmOTM * { box-sizing: border-box; }", ".framer-QmOTM .framer-1t6dex3 { position: relative; overflow: hidden; width: 1280px; height: 77px; }", ".framer-QmOTM .framer-1k0bxk4 { position: absolute; right: 0px; bottom: 0px; left: 0px; top: 0px; flex: none; display: flex; flex-direction: column; justify-content: center; align-content: flex-start; align-items: flex-start; flex-wrap: nowrap; gap: 10px; padding: 0px 0px 0px 0px; }", ".framer-QmOTM .framer-nkxw7 { position: relative; overflow: visible; width: auto; height: auto; flex: none; white-space: pre; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-QmOTM framer-1k0bxk4 > * { margin: 0px; margin-top: calc(10px / 2); margin-bottom: calc(10px / 2); } .framer-QmOTM framer-1k0bxk4 > :first-child { margin-top: 0px; } .framer-QmOTM framer-1k0bxk4 > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 77
 * @framerIntrinsicWidth 1280
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerllNZuIInf: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerllNZuIInf;

FramerllNZuIInf.displayName = "Navbar";

FramerllNZuIInf.defaultProps = {width: 1280, height: 77};

addFonts(FramerllNZuIInf, []);